<template>
  <div class="mine">
    <div class="mine-box">
      <div class="bg"></div>
      <div class="top">
        <div class="info flex-x-start flex-xy-center">
          <img src="~assets/images/my/default-img.png" alt class="logo" />
          <div>
            <div class="info-name">{{ userType == 2 ? merchantStore.merchantStoreName : info.name }}</div>
            <div v-if="userType != 2" class="info-sort">经营类别：{{ businessScopeName }}</div>
            <div v-else class="info-sort">手机号：{{ merchantStore.account }}</div>
          </div>
        </div>
        <div class="line"></div>
        <div class="account">
          <div v-if="userType != 2" class="flex-x-start">
            <div class="account-left account-item flex-x-start flex-xy-center">
              <img src="~assets/images/my/account1.png" alt />
              <div>
                <p>{{ info.account }}</p>
                <span>商户账号(手机号)</span>
              </div>
            </div>
            <div class="account-right account-item flex-x-start flex-xy-center">
              <img src="~assets/images/my/account2.png" alt />
              <div>
                <p>{{ info.bankNumber }}</p>
                <span>银行卡号</span>
              </div>
            </div>
          </div>
          <!-- @2 -->
          <div class="rate flex-y-center">
            <span class="text-gray">结算费率:</span>
            <span>
              微信
              <span class="color-main">{{ settleFeeInfo.wxMerchantSettleRate || 0 }}%</span>
            </span>
            <span>
              支付宝
              <span class="color-main">{{ settleFeeInfo.aliMerchantSettleRate || 0 }}%</span>
            </span>
          </div>
        </div>
        <div v-if="merchantInfo && merchantInfo.handlingFeeType != 0" class="vip color-white m-t-10">
          <div class="flex-x-between flex-y-center bg-opacity">
            <div class="flex-x-start flex-y-center">
              <div>
                <img src="../../assets/images/my/crown.png" alt />
              </div>
              <div v-if="merchantInfo.handlingFeeType == 1" class="text-13 m-l-5">
                优质商户免交易手续费
              </div>
              <div v-if="merchantInfo.handlingFeeType == 2" class="text-13 m-l-5">
                优质商户免刷脸手续费
              </div>
            </div>
            <div class="text-13 opacity-08">有效期：{{ merchantInfo.deductionEndTime || '-' }}</div>
          </div>
          <div class="flex-x-between flex-y-center p-all-10">
            <div>
              <div class="text-31">
                {{ (merchantInfo.totalDeductionHandlingFee || 0).toFixed(2) || 0 }}
              </div>
              <div class="text-13 opacity-08 m-t-10">总减免金额</div>
            </div>
            <div>
              <div class="text-13 flex-x-end flex-y-center m-t-10">
                <span class="opacity-08">已减免金额：</span>
                <span class="text-15">{{ (merchantInfo.currentDeductionHandlingFee || 0).toFixed(2) || 0 }}</span>
              </div>
              <div class="text-13 flex-x-end flex-y-center m-t-10">
                <span class="opacity-08">剩余减免金额：</span>
                <span class="text-15">
                  {{ (merchantInfo.monthHandlingFee - merchantInfo.currentDeductionHandlingFee).toFixed(2) }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="serve m-t-10">
          <div class="title">我的服务</div>
          <div v-for="(item, index) in cells" :key="index" class="list" @click="jump(item.url)">
            <div v-if="item.enable" class="flex-x-between flex-xy-center list-item">
              <div class="flex-xy-center flex-x-start">
                <img :src="item.src" alt class="list-img" />
                <span class="custom-title">{{ item.name }}</span>
              </div>
              <img src="~assets/images/my/arrow-right.png" alt class="arrow-img" />
            </div>
            <div v-if="item.enable && index < cells.length - 1" class="divider"></div>
          </div>
        </div>
        <div v-if="customerService" class="customer-service">
          <div class="title">您的专属客服</div>
          <div v-if="customerService.contactPhone" class="flex-x-start flex-y-center">
            <img class="img-avatar" src="~/assets/images/my/salesman.png" alt />
            <div>{{ customerService.contactPhone }}</div>
            <img class="img-phone" src="~/assets/images/my/ic_call_phone.png" alt />
          </div>
          <div class="qrcode-wrap">
            <img v-if="customerService.customerQrCode" class="img-qrcode" :src="customerService.customerQrCode" />
            <div v-if="customerService.onlineCustomerLink" class="btn-contact" @click="onCustomerServiceContact">联系客服</div>
          </div>
        </div>
      </div>
    </div>
    <div class="block"></div>
    <foot-tabbar />
  </div>
</template>

<script>
import FootTabbar from '@/components/FootTabbar'
import { settleFee, merchantInfo, userInfo, openPersonnel, getSettlePayConfig, getDeviceRewardConfig } from '@/api/common'
import { getCustomerServiceCondfig } from '@/api/powerbank'
import { clearCookie } from '@/utils/cookie'

export default {
  name: 'Mine',
  data() {
    return {
      info: '',
      bankName: '',
      businessScopeName: '',
      settleFeeInfo: '',
      merchantInfo: '',
      merchantStore: '',
      openPersonnel: '',
      customerService: '',
      userType: localStorage.getItem('userType'),
      cells: [
        {
          src: require('assets/images/my/order.png'),
          name: '查看提现订单',
          url: '/order',
          enable: false,
        },
        {
          src: require('assets/images/my/bind.png'),
          name: '绑定收款微信',
          url: '/bindWx',
          enable: false,
        },
        {
          src: require('assets/images/my/bind.png'),
          name: '绑定支付宝',
          url: '/bindAlipay',
          enable: false,
        },
        {
          src: require('assets/images/my/pwd.png'),
          name: '修改账号',
          url: '/editAccount',
          enable: true,
        },
        {
          src: require('assets/images/my/pwd.png'),
          name: '修改密码',
          url: '/editPwd',
          enable: true,
        },
        {
          src: require('assets/images/my/QRcode.png'),
          name: '收款二维码和音响',
          url: '/paymentCode',
          enable: true,
        },
        {
          src: require('assets/images/my/ic_merchant.png'),
          name: '门店管理',
          url: '/store',
          enable: false,
        },
        {
          src: require('assets/images/my/close.png'),
          name: '退出登录',
          url: '',
          enable: true,
        },
      ],
    }
  },
  created() {
    const bankNameStr = localStorage.getItem('bankName')
    const businessScopeNameStr = localStorage.getItem('businessScopeName')
    const payInfoStr = localStorage.getItem('payInfo')
    const merchantStoreStr = localStorage.getItem('merchantStore')
    console.log('userType', this.userType)
    console.log('bankNameStr', bankNameStr)
    console.log('businessScopeNameStr', businessScopeNameStr)
    console.log('payInfoStr', payInfoStr)
    console.log('merchantStoreStr', merchantStoreStr)

    if (bankNameStr && bankNameStr != 'undefined') {
      this.bankName = JSON.parse(bankNameStr)
    }
    if (businessScopeNameStr && businessScopeNameStr != 'undefined') {
      this.businessScopeName = JSON.parse(businessScopeNameStr)
    }
    if (payInfoStr && payInfoStr != 'undefined') {
      this.info = JSON.parse(payInfoStr)
    }

    if (merchantStoreStr && merchantStoreStr != 'undefined') {
      this.merchantStore = JSON.parse(merchantStoreStr)
    }

    this.getSettleFee()
    this.getMerchantInfo()
    this.getOpenPersonnel()
    this.getSettlePayConfig()
    this.getDeviceRewardConfig()
    this.loadCustomerServiceConfig()

    if (this.userType != 2) {
      this.cells.find(item => item.url === '/store').enable = true
    }
  },
  methods: {
    loadCustomerServiceConfig() {
      getCustomerServiceCondfig().then(res => {
        if (res.code != 200) return
        const data = res.data
        if (data && (data.contactPhone || data.customerQrCode || data.onlineCustomerLink)) {
          this.customerService = data
        }
      })
    },
    jump(url) {
      if (url) {
        this.$router.push(url)
      } else {
        this.$dialog
          .confirm({
            title: '提示',
            message: '确定要退出当前账号吗？',
          })
          .then(() => {
            clearCookie()
            this.callNativeLogout()
            this.$store.dispatch('logout')
            this.$router.replace('/login')
          })
      }
    },
    callNativeLogout() {
      if (window.webkit) {
        if (window.webkit.messageHandlers.logout) window.webkit.messageHandlers.logout.postMessage({})
      } else if (window.android) {
        if (window.android.logout) window.android.logout()
      }
    },
    getSettleFee() {
      settleFee().then(res => {
        this.settleFeeInfo = res.data
      })
    },
    getMerchantInfo() {
      const func = this.userType == 2 ? userInfo : merchantInfo
      func().then(res => {
        const data = this.userType == 2 ? res.data.merchant : res.data
        if (data) {
          const time = data.deductionEndTime
          if (time && time.length > 10) {
            data.deductionEndTime = data.deductionEndTime.substr(0, 10)
          }
          this.merchantInfo = data
        }
        console.log(this.merchantInfo)
      })
    },
    getOpenPersonnel() {
      openPersonnel().then(res => {
        this.openPersonnel = res.data
        console.log(this.openPersonnel)
      })
    },
    getSettlePayConfig() {
      getSettlePayConfig().then(res => {
        let { aliPayOpen, wxPayOpen } = res.data
        console.log('getSettlePayConfig', aliPayOpen, wxPayOpen)
        if (wxPayOpen) {
          const data = this.cells.find(item => item.url == '/bindWx')
          data.enable = true
        }
        if (this.userType != 2 && aliPayOpen) {
          const data = this.cells.find(item => item.url == '/bindAlipay')
          data.enable = true
        }
      })
    },
    getDeviceRewardConfig() {
      getDeviceRewardConfig().then(res => {
        if (this.userType != 2 && res.code == 200) {
          this.cells[0].enable = res.data.openSwitch
        }
      })
    },
    onCustomerServiceContact() {
      window.open(this.customerService?.onlineCustomerLink, '_blank')
    }
  },
  components: {
    FootTabbar,
  },
}
</script>

<style lang="less" scoped>
.mine {
  font-size: 14px;
  .mine-box {
    .bg {
      width: 375px;
      height: 97px;
      background-image: url('~assets/images/my/bg.png');
      background-size: 100% 100%;
    }
    .top {
      padding: 15px;
      margin-top: -97px;
      .info {
        padding: 15px;
        background: #fff;
        .logo {
          height: 69px;
          width: 69px;
          border-radius: 50%;
          margin-right: 14px;
        }
        .info-name {
          font-size: 16px;
        }
        .info-sort {
          background: rgba(253, 110, 5, 0.1);
          border-radius: 2px;
          color: rgba(51, 51, 51, 0.7);
          font-size: 11px;
          padding: 4px 6px;
          margin: 5px 0;
        }
        .info-bank {
          font-size: 11px;
          color: rgba(51, 51, 51, 0.7);
        }
      }
      .line {
        margin: 0 15px;
        border-bottom: 1px solid rgba(112, 112, 112, 0.08);
      }
      .account {
        background: #fff;
        padding: 11px 15px 17px 15px;
        .account-item {
          img {
            width: 24px;
            height: 24px;
          }
          div {
            p {
              font-size: 13px;
              color: rgba(51, 51, 51, 0.85);
            }
            span {
              font-size: 12px;
              color: rgba(51, 51, 51, 0.7);
            }
          }
        }
        .account-left {
          margin-right: 37px;
        }
        .rate {
          height: 40px;
          background: #f8f8f8;
          margin: 10px 0;
          padding: 20px;
          border-radius: 5px;
        }
        span {
          margin-right: 10px;
        }
        .text-gray {
          color: #999999;
        }
        .color-main {
          color: #fc7613;
        }
      }
      .vip {
        background: url(../../assets/images/my/bg2.png) no-repeat;
        background-size: 100% 100%;
        padding-bottom: 15px;
        .p-all-10 {
          padding: 10px;
        }
        .m-l-5 {
          margin-left: 5px;
        }
        .m-t-10 {
          margin-top: 10px;
        }
        .bg-opacity {
          background: rgba(255, 255, 255, 0.15);
          padding: 10px;
        }
        .opacity-08 {
          opacity: 0.8;
        }
        .color-white {
          color: #ffffff;
        }
        .text-31 {
          font-size: 31px;
          font-weight: 700;
        }
        .text-13 {
          font-size: 13px;
        }
        .text-15 {
          font-size: 15px;
          font-weight: 500;
        }
      }
      .serve {
        padding: 15px 20px;
        background: #fff;
        .title {
          font-size: 16px;
        }
        .list-item {
          padding: 19px 0 12px 0;
          .list-img {
            height: 24px;
            width: 24px;
            margin-right: 14px;
          }
          .arrow-img {
            height: 17px;
            width: 17px;
          }
        }
        .divider {
          height: 1px;
          background-color: rgba(112, 112, 112, 0.1);
        }
      }
    }
  }
}
.block {
  height: 50px;
}

.customer-service {
  margin: 14px 0;
  padding: 13px 0 6px 20px;
  background: #fff;
  font-size: 14px;
  color: #333;

  .title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 12px;
  }
  .img-avatar {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  .img-phone {
    width: 20px;
    height: 20px;
    margin-left: 10px;
  }

  .qrcode-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    padding: 15px;
    border-top: 1px solid #eee;
    .img-qrcode {
      width: 159px;
      height: 159px;
    }

    .btn-contact {
      padding: 8px 37px;
      margin-top: 16px;
      border: 1px solid #FD6E05;
      border-radius: 20px;
      color: #FD6E05;
      font-size: 14px;
    }
  }
}
</style>
